<template>
  <div
    @dragstart="getDragstartEvent"
    @dragend="clearDraggedItem"
  >
    <slot
      :acceptDrop="acceptDrop"
      :acceptDropGlobal="acceptDropGlobal"
      :setDraggable="draggableEventListeners"
    ></slot>
  </div>
</template>
<script>
function generateEventListeners (dropAction, dragTargetItem, dragstartEvent, clearDraggedItem) {
  if (!dragTargetItem) {
    return {}
  }
  // dragTargetItem.draggableEventListeners = this.draggableEventListeners // This allow deep drag and drop but increase the payload
  return {
    dragover: e => e.preventDefault(),
    drop: ($event, ...args) => {
      dropAction(dragTargetItem, dragstartEvent, $event, ...args)
      $event.preventDefault()
    }
  }
}

export default {
  data () {
    return {
      dragstartEvent: undefined,
      dragTargetItem: undefined
    }
  },
  methods: {
    acceptDrop: function (dropAction) {
      return generateEventListeners.call(this, dropAction, this.dragTargetItem, this.dragstartEvent)
    },
    acceptDropGlobal: function (dropAction) {
      return generateEventListeners.call(this, dropAction, this.$store.getters.dragTargetItem, this.$store.getters.dragstartEvent)
    },
    clearDraggedItem () {
      this.dragstartEvent = undefined
      this.dragTargetItem = undefined
      this.$store.commit('setDragTargetItem', this.dragstartEvent)
      this.$store.commit('setDragstartEvent', this.dragTargetItem)
    },
    draggableEventListeners (target) {
      return {
        dragstart: () => {
          this.dragTargetItem = target
          this.$store.commit('setDragTargetItem', this.dragTargetItem)
        },
        dragend: this.clearDraggedItem
      }
    },
    getDragstartEvent (e) {
      this.dragstartEvent = e
      this.$store.commit('setDragstartEvent', this.dragstartEvent)
    }
  }
}
</script>
<style lang="stylus" scoped>
>>> [draggable]
  cursor grab
</style>
